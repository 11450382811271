/* eslint-disable */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { FormControl, InputLabel, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import "./../../products/new-product/spin.css";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function Loading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      class="spin"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
}
function NewInspiration() {
  const [enteredName, setEnteredName] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [seletedDesigners, setSelectedDesigners] = useState([]);
  const [data, setData] = useState({});
  const [credit, setCredit] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData] = useState(new FormData());
  const [priority, setPriority] = useState(null);

  useEffect(() => {
    api.get("/api/admin/inspiration/productlist").then((response) => {
      setProducts(response.data.data);
    });
  }, []);

  useEffect(() => {
    api.get("/api/admin/categories?itmes=50000").then((response) => {
      setData(response.data.data);
    });

    api.get("/api/admin/designers?items=50000").then((response) => {
      setDesigners(response.data.data.data);
    });
  }, []);

  useEffect(() => {
    if (data.inspiration && data) setCategories([...data.inspiration, ...data.product]);
  }, [data]);

  function handleImageChange(event) {
    const { files } = event.target;

    if (files.length > 0) {
      Array.from(files).forEach((file) => {
        formData.append(`image[]`, file);
      });
    }
  }

  function newInspirationHandler() {
    const imageInput = document.getElementById("image").files.length;

    if (enteredName && selectedCategories && seletedDesigners && formData && imageInput >= 0) {
      setLoading(true);
      const params = {
        name: enteredName,
        description: enteredDescription,
        credit: credit,
        product: selectedProducts,
        category: selectedCategories,
        designers: seletedDesigners,
        image: formData,
        priority: priority,
      };
      api
        .post("api/admin/inspiration/create", params)
        .then((response) => {
          if (!formData.entries().next().done) {
            api
              .post(`api/admin/inspiration/${response.data.data.id}/image/add`, formData, {
                headers: {
                  "Content-Type": "multipart-form-data",
                },
              })
              .then((res) => {
                toast.success("Successfully created inspiration!");
                setLoading(false);
                window.location.reload();
              })
              .catch(console.error);
          } else {
            toast.success("Successfully created inspiration!");
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Wrong parameters");
        });
    } else if (!enteredName) {
      toast.error("Name is required field!");
    } else if (!selectedCategories) {
      toast.error("Please select at least one category!");
    } else if (!setSelectedDesigners) {
      toast.error("Please select at least one designer!");
    } else {
      toast.error("Please add an image!");
    }
  }
  const handleChangeProduct = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProducts(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeCategories = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeDesigners = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDesigners(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Add new inspiration
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Inspiration Information
                </MDTypography>
                <MDBox mt={1} component="form" role="form" onSubmit={() => newInspirationHandler}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Credit"
                        value={credit}
                        onChange={(e) => setCredit(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        multiline
                        rows={5}
                        label="Description"
                        value={enteredDescription}
                        onChange={(e) => setEnteredDescription(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">Products</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedProducts}
                        onChange={handleChangeProduct}
                        input={<OutlinedInput label="Products" />}
                        MenuProps={MenuProps}
                        sx={{ height: 40 }}
                      >
                        {products.length &&
                          products?.map((product) => (
                            <MenuItem key={product.id} value={product.id}>
                              {product.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">Categories</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedCategories}
                        onChange={handleChangeCategories}
                        input={<OutlinedInput label="Categories" />}
                        MenuProps={MenuProps}
                        sx={{ height: 40 }}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">Designer</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={seletedDesigners}
                        onChange={handleChangeDesigners}
                        input={<OutlinedInput label="Designer" />}
                        MenuProps={MenuProps}
                        sx={{ height: 40 }}
                      >
                        {designers.map((designer) => (
                          <MenuItem key={designer.id} value={designer.id}>
                            {designer.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} s={12}>
                    <Typography variant="caption" display="block" gutterBottom pt={1} m={2}>
                      <Checkbox
                        checked={priority}
                        onChange={(event) => setPriority(event.target.checked)}
                      />
                      Priority
                    </Typography>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12}>
                    <input id="image" type="file" onChange={handleImageChange} multiple />
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid item xs={12} lg={6} mb={4}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={newInspirationHandler}>
                    {loading ? <Loading /> : "Save"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewInspiration;
