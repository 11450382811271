/* eslint-disable */

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function EditProperty() {
  const [enteredName, setEnteredName] = useState("");
  const [propertyValues, setPropertyValues] = useState([]);
  const [multiple, setMultiple] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("api/admin/properties")
      .then((response) => {
        const property = response.data.data.find((item) => item.id === id);
        setPropertyValues(property.values);
        setEnteredName(property.name);
        setMultiple(property.multiple);
      })
      .catch(console.error);
  }, []);

  const handleMultipleChange = () => {
    setMultiple(!multiple);
  };

  const handlePropertyValueChange = (propertyId, value) => {
    const allProperties = [...propertyValues];
    const property = allProperties.find((prop) => prop.id === propertyId);
    allProperties[allProperties.indexOf(property)].value = value;
    setPropertyValues(allProperties);
  };

  const addPropertyInput = () => {
    const allProperties = [...propertyValues];
    const randId = Math.floor(999999 * Math.random());
    allProperties.push({
      id: randId,
      property_id: id,
      value: "",
    });
    setPropertyValues(allProperties);
  };

  const removePropertyInput = (propertyId) => {
    const allProperties = [...propertyValues];
    allProperties.splice(
      allProperties.indexOf(allProperties.find((prop) => prop.id === propertyId)),
      1
    );
    setPropertyValues(allProperties);
  };

  const editPropertyHandler = () => {
    const params = {
      name: enteredName,
      allow_multiple: multiple,
      property_values: propertyValues,
    };
    api
      .post(`api/admin/property/${id}`, params)
      .then((response) => {
        console.log(response);
        toast.success("Successfully updated property!");
        navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Wrong parameters");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={enteredName} />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit property
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Property Information
                </MDTypography>
                <MDBox mt={1} component="form" role="form" onSubmit={() => editPropertyHandler()}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Switch checked={multiple} onChange={handleMultipleChange} />}
                          label="Multiple allowed"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <MDTypography variant="h5" display="flex" justifyContent="center">
                        Property values
                      </MDTypography>
                    </Grid>
                  </Grid>
                  {propertyValues.map((property) => (
                    <Grid container item xs={12} key={property.id}>
                      <Grid item xs={10}>
                        <FormField
                          type="text"
                          label="value"
                          value={property.value}
                          onChange={(e) => handlePropertyValueChange(property.id, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <MDButton
                          variant="gradient"
                          color="warning"
                          iconOnly
                          onClick={() => removePropertyInput(property.id)}
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </Grid>
                    </Grid>
                  ))}
                  <MDButton variant="gradient" color="info" iconOnly onClick={addPropertyInput}>
                    <Icon>add</Icon>
                  </MDButton>
                </MDBox>
              </MDBox>
              <Grid item xs={12} lg={6} mb={3}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={() => editPropertyHandler()}>
                    save
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditProperty;
