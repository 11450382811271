/* eslint-disable */

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { useNavigate, useParams } from "react-router-dom";

function EditColor() {
  const [enteredName, setEnteredName] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    api
      .get("api/admin/colors")
      .then((response) => {
        const color = response.data.data.find((item) => item.id === id);
        setEnteredName(color.name);
      })
      .catch(console.error);
  }, []);

  function editColorHandler() {
    const params = { name: enteredName };
    api
      .post(`api/admin/color/${id}`, params)
      .then((response) => {
        console.log(response);
        toast.success("Successfully updated color!");
        navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Wrong parameters");
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={enteredName} />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit color
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Color Information
                </MDTypography>
                <MDBox mt={1} component="form" role="form" onSubmit={() => editColorHandler()}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid item xs={12} lg={6} mb={3}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={() => editColorHandler()}>
                    save
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditColor;
