/* eslint-disable react/prop-types */
import { createContext, useMemo, useState } from "react";

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) ?? null);

  if (user !== null) localStorage.setItem("user", JSON.stringify(user));

  return (
    <AuthContext.Provider value={useMemo(() => [user, setUser])}>{children}</AuthContext.Provider>
  );
}
