/* eslint-disable */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

function EditCategory() {
  const [enteredName, setEnteredName] = useState("");
  const [type, setType] = useState("");
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    api.get("/api/admin/categories").then((response) => {
      console.log("res", response.data.data);
      setData(response.data.data);
    });
  }, []);

  useEffect(() => {
    console.log("data", data);
    if (data.inspiration && data.product) {
      console.log([...data.inspiration, ...data.product]);
      setCategories([...data.inspiration, ...data.product]);
    }
  }, [data]);

  useEffect(() => {
    console.log(categories);
    const category = categories.find((item) => item.id === id);
    setEnteredName(category?.name);
    setType(category?.type);
  }, [categories]);

  function editCategoryHandler() {
    const params = { name: enteredName, type: type };
    api
      .post(`api/admin/category/${id}`, params)
      .then((response) => {
        console.log(response);
        toast.success("Successfully updated category!");
        setEnteredName("");
        setType("");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Wrong parameters");
      });
  }

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const category = categories.find((item) => item.id === id);
  const [checkboxValue, setCheckboxValue] = useState(category?.is_default);

  useEffect(() => {
    setCheckboxValue(category?.is_default === 0 ? 0 : 1);
  }, [category]);

  function setAsDefault(event) {
    if (event.target.checked) {
      setCheckboxValue(1);
      console.log("checked");
      api.post(`api/admin/category/${id}/set-default`, { is_default: 1 }).then((res) => {
        console.log(res);
        toast.success("Successfully set as default!");
      });
    } else {
      setCheckboxValue(0);
      console.log("unchecked");
      api.post(`api/admin/category/${id}/set-default`, { is_default: 0 }).then((res) => {
        console.log(res);
        toast.success("Successfully unset as default!");
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={enteredName} />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit category
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Category Information
                </MDTypography>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Checkbox
                    checked={checkboxValue === 1 ? true : false}
                    onChange={(event) => setAsDefault(event)}
                  />
                  <Typography variant="caption" display="block" gutterBottom pt={1}>
                    Set as default
                  </Typography>
                </Grid>
                <MDBox mt={1} component="form" role="form" onSubmit={() => editCategoryHandler}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={type}
                          label="Type"
                          onChange={handleChange}
                          sx={{ height: 40 }}
                        >
                          <MenuItem value="product">Product</MenuItem>
                          <MenuItem value="inspiration">Inspiration</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid item xs={12} lg={6} mb={3}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={() => editCategoryHandler()}>
                    save
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditCategory;
