/* eslint-disable */
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import api from "services/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function UsersList() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [paginationData, setPaginationData] = useState({
    total: 0,
    current_page: 1,
    per_page: 24,
    from: 0,
    to: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [userTypes, setUserTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  const getUsers = (type) => {
    const hasType = type ?? selectedType !== null;

    api
      .post(`/api/admin/user/search?page=${page + 1}${hasType ? `&type=${selectedType}` : ""}`)
      .then((response) => {
        setPaginationData(response.data.data.meta);
        setData(response.data.data.data);
      });
  };

  const getUserTypes = () => {
    api.get(`/api/admin/usertypes`).then((response) => {
      setUserTypes(response.data.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  useEffect(() => {
    getUserTypes();

    setTimeout(() => {
      const openPage = searchParams.get("page");

      if (openPage) {
        setPage(+openPage - 1);
      }
    }, 300);
  }, []);

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setSearchParams(`?${new URLSearchParams({ page: newPage + 1, type: selectedType })}`);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  function newUserButton() {
    navigate("/pages/user/new-user");
  }

  function deleteUser() {
    setOpen(false);
    api
      .delete(`api/admin/user/${selectedId}`)
      .then((response) => {
        console.log(response);
        toast.success("Successfully deleted user!");
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Some error occured!");
      });
  }
  const onDeleteHandler = (id) => {
    setSelectedId(id);
    setOpen(true);
  };
  function editUser(id) {
    navigate(`/pages/user/edit-user/${id}`);
  }

  function handleInputChange(event) {
    setSelectedType(event.target.innerText);
    event.preventDefault();
    if (event.target.innerText) {
      api.post(`api/admin/user/search?type=${event.target.innerText}`).then((response) => {
        console.log("filtered", response.data.data);
        setData(response.data.data.data);
        setPaginationData(response.data.data.meta);
      });
    } else {
      setSelectedType(null);
      getUsers(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => newUserButton()}>
            new user
          </MDButton>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Autocomplete
              sx={{ width: 200 }}
              options={userTypes}
              getOptionLabel={(option) => option.type || ""}
              renderInput={(params) => <TextField {...params} label="Filter by type" />}
              onInputChange={handleInputChange}
            />
          </div>
        </MDBox>

        <TableContainer component={Paper} sx={{ Height: 500 }}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <strong>First Name</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Last Name</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Email</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
              {data.map((row) => (
                <TableRow key={row.id} id={row.id}>
                  <TableCell component="th" scope="row">
                    {row.firstname}
                  </TableCell>
                  <TableCell align="left">{row.lastname}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">
                    <Icon
                      fontSize="small"
                      sx={{ color: "blue", cursor: "pointer" }}
                      onClick={() => editUser(row.id)}
                    >
                      edit
                    </Icon>
                    <Icon
                      fontSize="small"
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={() => onDeleteHandler(row.id)}
                    >
                      delete
                    </Icon>
                  </TableCell>
                </TableRow>
              ))}
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this user?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>No</Button>
                  <Button onClick={() => deleteUser()} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[24]}
            colSpan={3}
            component="div"
            count={paginationData.total}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
      </MDBox>
    </DashboardLayout>
  );
}

export default UsersList;
