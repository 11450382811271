/* eslint-disable */

import axios from "axios";

const api = axios.create();

// api.defaults.baseURL = "https://pfc-api.deboprojects.com";
api.defaults.baseURL = "https://pfc-api-prod.deboprojects.com";

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const override = Boolean(localStorage.getItem("override"));
    if (override) return Promise.reject(error);

    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location = "/authentication/sign-in/basic";
    }
  }
);

export async function authenticate(enteredEmail, enteredPassword) {
  const url = `/api/login`;

  let authObj = {
    token: "",
    onboarded: "",
  };

  let result;

  try {
    result = await api.post(
      url,
      {
        email: enteredEmail,
        password: enteredPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    authObj = {
      token: result.data.data.token,
      onboarded: result.data.data.onboarded,
    };

    console.log(result);
  } catch (error) {
    console.error(error);
  }

  return authObj;
}

export async function getUserData() {
  return api.get("/api/me");
}

export default api;
