/* eslint-disable */
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import api from "services/api";
import { useState, useEffect } from "react";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import { Typography } from "@mui/material";

function Analytics() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [inspirations, setInspirations] = useState([]);
  const [products, setProducts] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [userTypesLength, setUserTypesLength] = useState("");
  const [type, setType] = useState("");
  const [totalType, setTotalType] = useState("");

  useEffect(() => {
    api.get("/api/admin/categories").then((response) => {
      setData(response.data.data);
    });

    api.get("/api/admin/inspirations").then((response) => {
      setInspirations(response.data.data.meta.total);
    });

    api.get("/api/admin/products").then((response) => {
      setProducts(response.data.data.meta.total);
    });
    api.get(`/api/admin/usertypes`).then((response) => {
      setUserTypes(response.data.data);
      response.data.data.map((item) => {
        setType(item.type);
        setTotalType(item.total);
      });
      setUserTypesLength(response.data.data.length);
    });
  }, []);

  useEffect(() => {
    if (data.inspiration && data) setCategories([...data.inspiration, ...data.product]);
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={15}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="shopping-cart"
                  title="Products"
                  count={products}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard icon="lens-blur" title="Inspirations" count={inspirations} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="category"
                  title="Categories"
                  count={categories?.length}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="lens-blur"
                  title="Categories"
                  count={categories?.length}
                />
                <div style={{ marginTop: 10 }}>
                  {userTypes.length > 0 &&
                    userTypes.map((item) => (
                      <MDBadge
                        style={{ marginRight: 5 }}
                        badgeContent={
                          <>
                            <div>
                              {item.type}: {item.total}
                            </div>
                          </>
                        }
                        size="xs"
                        container
                      />
                    ))}
                </div>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Analytics;
