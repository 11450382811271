/* eslint-disable */

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import api from "services/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {FormControl, InputLabel, Select, TablePagination} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { toast } from "react-toastify";
// import MDInput from "components/MDInput";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MDInput from "components/MDInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function ProductList() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    current_page: 1,
    per_page: 24,
    from: 0,
    to: 0,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [rowsPerPage, setRowsPerPage] = useState(24);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [sortBy, setSortBy] = useState("asc");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      const openPage = searchParams.get("page");

      if (openPage) {
        setPage(+openPage - 1);
      }
    }, 300);
  }, []);

  const getCategories = () => {
    api.get(`/api/admin/categories`).then((response) => {
      setCategories([{id: 0, name: "All"}, ...response.data.data.product])
    })
  }

  const getProducts = () => {
    api.get(`/api/admin/products?page=${page + 1}`).then((response) => {
      console.log(response.data.data.data);
      setPaginationData(response.data.data.meta);
      setData(response.data.data.data);
    });
  };

  useEffect(() => {
    if (searchCategory || searchTerm) {
      handleSubmit()
    } else {
      getProducts();
    }
  }, [page]);

  useEffect(() => {
    getCategories()
  }, [])

  const handleChangePage = (event, newPage) => {
    setSearchParams(`?${new URLSearchParams({ page: newPage + 1 })}`);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function newProductButton() {
    navigate("/pages/products/new-product");
  }

  function deleteProduct() {
    setOpen(false);
    api
      .delete(`api/admin/product/${selectedId}`)
      .then((response) => {
        console.log(response);
        toast.success("Successfully deleted product!");
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Some error occured!");
      });
  }

  const onDeleteHandler = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  function editProduct(id) {
    navigate(`/pages/products/edit-product/${id}`);
  }

  function incompleteProducts(event) {
    if (!event.target.checked) {
      getProducts();
    } else {
      api.get(`/api/admin/products/incomplete?page=${page + 1}`).then((response) => {
        setPaginationData(response.data.data.meta);
        setData(response.data.data.data);
      });
    }
  }

  function filterByPriority(event) {
    if (!event.target.checked) {
      getProducts();
    } else {
      api.get(`/api/admin/products?sort=priority`).then((response) => {
        setData(response.data.data.data);
        setPaginationData(response.data.data.meta);
        // setRowsPerPage(response.data.data.meta.per_page);
      });
    }
  }

  const handleChange = (event) => {
    setPage(0)
    setSearchTerm(event.target.value);
  };

  const setCategorySearch = (event) => {
    setPage(0)
    setSearchCategory(event.target.value)
  }

  useEffect(() => {
    handleSubmit()
  }, [searchCategory])

  const handleSubmit = () => {
    let data = {
      page: page+1
    }
    if (searchTerm) {
      data.query = searchTerm
    }

    if(searchCategory && searchCategory !== 0) {
      data.category_id = [searchCategory]
    }

    if(Object.keys(data).length > 0) {
      api.post(`api/admin/product/search`, data).then((response) => {
        setData(response.data.data.data);
        setPaginationData(response.data.data.meta);
      });
    } else {
      getProducts();
    }
  };

  const toggleSort = () => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    api.get(`/api/admin/products?sort=name&dir=${sortBy}`).then((response) => {
      setData(response.data.data.data);
      setPaginationData(response.data.data.meta);
    });
  }, [sortBy]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => newProductButton()}>
            new product
          </MDButton>
        </MDBox>

        <TableContainer component={Paper} sx={{ Height: 500 }}>
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={3}>
              <MDBox pr={1} m={2}>
                <form action="" onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}>
                  <MDInput label="Search here" onChange={handleChange} />
                </form>
              </MDBox>
            </Grid>
            <Grid item sm={3}>
              <FormControl sx={{ width: "100%" }}>
              <InputLabel id="select-category-label">Category</InputLabel>
                <Select
                labelId="select-category-label"
                id="select-category"
                value={searchCategory}
                onChange={setCategorySearch}
                input={<OutlinedInput label="Category" />}
                MenuProps={MenuProps}
                sx={{ height: 40 }}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Grid>
            <Typography variant="caption" display="block" gutterBottom pt={1} m={2}>
              Show unfilled products
              <Checkbox onClick={(event) => incompleteProducts(event)} />
            </Typography>
            <Typography variant="caption" display="block" gutterBottom pt={1} m={2}>
              Filter by priority
              <Checkbox onClick={(event) => filterByPriority(event)} />
            </Typography>
          </Grid>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <strong>Images</strong>
                </TableCell>
                <TableCell align="left" style={{ cursor: "pointer" }} onClick={toggleSort}>
                  <strong>Name</strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    style={{ width: "1rem", marginLeft: "0.5rem" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                    />
                  </svg>
                </TableCell>
                <TableCell align="left">
                  <strong>Description</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="left">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {row.images.map((image) => (
                        <img
                          src={image.url}
                          alt={image.name}
                          key={image.id}
                          style={{
                            marginLeft: "1rem",
                            width: "2rem",
                            height: "2rem",
                            borderRadius: "100%",
                            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                          }}
                        />
                      ))}
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                    {/* <img src={row.images.name} alt={row.images.name} /> */}
                  </TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                  <TableCell align="left">
                    <Icon
                      fontSize="small"
                      sx={{ color: "blue", cursor: "pointer" }}
                      onClick={() => editProduct(row.id)}
                    >
                      edit
                    </Icon>
                    <Icon
                      fontSize="small"
                      onClick={() => onDeleteHandler(row.id)}
                      sx={{ color: "red", cursor: "pointer" }}
                    >
                      delete
                    </Icon>
                  </TableCell>
                </TableRow>
              ))}
              {/* {open && ( */}
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this product?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)}>No</Button>
                  <Button onClick={() => deleteProduct()} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[24]}
            colSpan={3}
            component="div"
            count={paginationData.total}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
      </MDBox>
    </DashboardLayout>
  );
}

export default ProductList;
