/* eslint-disable */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { FormControl, InputLabel, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditInspiration() {
  const [enteredName, setEnteredName] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [designers, setDesigners] = useState([]);
  const [data, setData] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDesigners, setSelectedDesigners] = useState([]);
  const [img, setImg] = useState("");
  const [inspiration, setInspiration] = useState([]);
  const [enteredCredit, setEnteredCredit] = useState("");
  const [priority, setPriority] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    api.get("/api/admin/inspiration/productlist").then((response) => {
      setProducts(response.data.data);
    });

    api.get("/api/admin/categories").then((response) => {
      console.log("two into one", response.data.data);
      setData(response.data.data);
    });

    api.get("/api/admin/designers").then((response) => {
      setDesigners(response.data.data.data);
    });

    api.get(`/api/admin/inspiration/${id}`).then((response) => {
      setInspiration(response.data.data);
    });
  }, []);

  useEffect(() => {
    console.log("data", data);
    if (data.inspiration && data) setCategories([...data.inspiration, ...data.product]);
  }, [data]);

  useEffect(() => {
    console.log({ categories });
  }, [categories]);

  const handleChangeProduct = (event) => {
    const {
      target: { value },
    } = event;
    console.log("value:", value);
    setSelectedProducts(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log(selectedProducts);
  };

  useEffect(() => {
    setEnteredName(inspiration?.name);
    setEnteredCredit(inspiration?.credit);
    setEnteredDescription(inspiration?.description);
    setPriority(inspiration?.priority);

    let splitCategories;

    if (inspiration.categories?.length) {
      splitCategories = inspiration?.categories.map((cat) => cat.id);
      setSelectedCategories(splitCategories);
    }

    let splitProducts;

    if (inspiration.products?.length) {
      splitProducts = inspiration?.products.map((product) => product.id);
      setSelectedProducts(splitProducts);
    }

    let splitDesigners;

    if (inspiration.designers?.length) {
      splitDesigners = inspiration?.designers.map((des) => des.id);
      setSelectedDesigners(splitDesigners);
    }

    // setImg(inspiration?.images[0].name);
  }, [inspiration]);

  useEffect(() => {
    console.log(selectedProducts);
  }, [selectedProducts]);

  useEffect(() => {
    console.log(selectedCategories);
  }, [selectedCategories]);

  const formData = new FormData();

  function handleImageChange(event) {
    console.log(event);

    const { files } = event.target;

    console.log(files);
    if (files.length > 0) {
      Array.from(files).forEach((file) => {
        formData.append(`image[]`, file);
      });
    }
    console.log(...formData);
  }

  function newInspirationHandler() {
    const params = {
      name: enteredName,
      credit: enteredCredit,
      description: enteredDescription,
      product: selectedProducts,
      category: selectedCategories,
      designers: selectedDesigners,
      priority: priority,
      // image: formData,
    };

    api
      .post(`api/admin/inspiration/${id}`, params)
      .then((response) => {
        if (!formData.entries().next().done) {
          api
            .post(`api/admin/inspiration/${response.data.data.id}/image/add`, formData, {
              headers: {
                "Content-Type": "multipart-form-data",
              },
            })
            .then((res) => {
              toast.success("Successfully updated inspiration!");
              navigate(-1);
            })
            .catch(console.error);
        } else {
          toast.success("Successfully updated inspiration!");
          navigate(-1);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Wrong parameters");
      });
  }

  const handleChangeCategories = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    console.log("161", value);
  };

  const handleChangeDesigners = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedDesigners(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    console.log("200", value);
  };

  function deleteImage(imageId) {
    api
      .post(`api/admin/inspiration/${id}/image/remove`, {
        image: [imageId],
      })
      .then((res) => {
        console.log("183 image delete", res);
        getData();
      })
      .catch(console.error);
  }

  const imageStyles = {
    width: "150px",
    height: "150px",
    borderRadius: "100%",
    objectFit: "cover",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={inspiration?.name} />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit inspiration
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Inspiration Information
                </MDTypography>
                <MDBox mt={1} component="form" role="form" onSubmit={() => newInspirationHandler}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Credit"
                        value={enteredCredit}
                        onChange={(e) => setEnteredCredit(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        multiline
                        rows={5}
                        label="Description"
                        value={enteredDescription ?? ""}
                        onChange={(e) => setEnteredDescription(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12} spacing={3}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">Products</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedProducts?.length ? selectedProducts : []}
                        onChange={handleChangeProduct}
                        input={<OutlinedInput label="Products" />}
                        MenuProps={MenuProps}
                        sx={{ height: 40 }}
                      >
                        {products.length > 0 &&
                          products.map((product) => (
                            <MenuItem key={product.id} value={product.id}>
                              {product.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12} spacing={3}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">Categories</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedCategories?.length ? selectedCategories : []}
                        onChange={handleChangeCategories}
                        input={<OutlinedInput label="Categories" />}
                        MenuProps={MenuProps}
                        sx={{ height: 40 }}
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12} spacing={3}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-name-label">Designers</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedDesigners?.length ? selectedDesigners : []}
                        onChange={handleChangeDesigners}
                        input={<OutlinedInput label="Designers" />}
                        MenuProps={MenuProps}
                        sx={{ height: 40 }}
                      >
                        {designers?.map((designer) => (
                          <MenuItem key={designer.id} value={designer.id}>
                            {designer.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} s={12}>
                    <Typography variant="caption" display="block" gutterBottom pt={1} m={2}>
                      <Checkbox
                        checked={priority}
                        onChange={(event) => setPriority(event.target.checked)}
                      />
                      Priority
                    </Typography>
                  </Grid>
                  <Grid item mt={2} xs={12} sm={12}>
                    <input type="file" onChange={handleImageChange} multiple />
                  </Grid>
                  {img}
                </MDBox>
              </MDBox>
              <Grid container item columns={12}>
                {inspiration.images?.map((image) => (
                  <Grid item xs={4} key={image.id} style={{ textAlign: "center" }}>
                    <img src={image.url} alt={image.name} style={imageStyles} />
                    <Icon
                      fontSize="small"
                      onClick={() => deleteImage(image.id)}
                      sx={{ color: "red", cursor: "pointer" }}
                    >
                      delete
                    </Icon>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} lg={6} mb={4}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={() => newInspirationHandler()}>
                    save
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditInspiration;
