/* eslint-disable */
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import SignInBasic from "layouts/authentication/sign-in/basic";
import ProductList from "layouts/pages/products/product-list";

import MDAvatar from "components/MDAvatar";

import Icon from "@mui/material/Icon";

import profilePicture from "assets/images/team-3.jpg";
// import ProductDetails from "layouts/pages/products/product-details";
import NewProduct from "layouts/pages/products/new-product";
import ColorList from "layouts/pages/colors/color-list";
import NewColor from "layouts/pages/colors/new-color";
import NewInspiration from "layouts/pages/inspirations/new-inspiration";
import InspirationList from "layouts/pages/inspirations/inspiration-list";
// import CategoriesList from "examples/Lists/CategoriesList";
import NewCategory from "layouts/pages/categories/new-category";
import CategoriesList from "layouts/pages/categories/categories-list";
import UsersList from "layouts/pages/user/users-list";
import NewUser from "layouts/pages/user/new-user";
import EditColor from "layouts/pages/colors/edit-color";
import EditUser from "layouts/pages/user/edit-user";
import PropertiesList from "layouts/pages/properties/property-list";
import NewProperty from "layouts/pages/properties/new-property";
import EditProperty from "layouts/pages/properties/edit-property";
import EditCategory from "layouts/pages/categories/edit-category";
import EditInspiration from "layouts/pages/inspirations/edit-inspiration";
import EditProduct from "layouts/pages/products/edit-product";
import DesignersList from "layouts/pages/designers/designers-list";
import NewDesginer from "layouts/pages/designers/new-designer";
import EditDesigner from "layouts/pages/designers/edit-designer";

const routes = [
  {
    type: "route",
    component: <SignInBasic />,
    route: "/authentication/sign-in/basic",
    key: "logout",
  },

  { type: "divider", key: "divider-0" },
  {
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboards/analytics",
    component: <Analytics />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboards/analytics",
    noCollapse: true,
  },
  {
    name: "Dashboard",
    key: "dashboard",
    path: "/dashboards/analytics",
    redirect: "/dashboards/analytics",
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="medium">shopping-cart</Icon>,
    collapse: [
      {
        name: "Product Page",
        key: "product-list",
        route: "/pages/products/product-list",
        component: <ProductList />,
      },
      {
        name: "New Product",
        key: "new-product",
        route: "/pages/products/new-product",
        component: <NewProduct />,
      },
    ],
  },
  {
    name: "Product Page",
    key: "product-list",
    path: "/products",
    redirect: "/pages/products/product-list",
  },
  {
    name: "Product Page",
    key: "product-list",
    path: "/edit-product",
    redirect: "/pages/products/product-list",
  },
  {
    type: "route",
    component: <EditProduct />,
    route: "/pages/products/edit-product/:id",
    key: "edit-product",
  },
  {
    type: "collapse",
    name: "Inspirations",
    key: "inspirations",
    icon: <Icon fontSize="medium">lens-blur</Icon>,
    collapse: [
      {
        name: "Inspirations Page",
        key: "inspiraton-list",
        route: "/pages/inspirations/inspiration-list",
        component: <InspirationList />,
      },
      {
        name: "New Inspiration",
        key: "new-inspiration",
        route: "/pages/inspirations/new-inspiraton",
        component: <NewInspiration />,
      },
    ],
  },
  {
    type: "route",
    component: <EditInspiration />,
    route: "/pages/inspirations/edit-inspiration/:id",
    key: "edit-inspiration",
  },
  {
    name: "Inspirations Page",
    key: "inspiraton-list",
    path: "/inspirations",
    redirect: "/pages/inspirations/inspiration-list",
  },
  {
    name: "Inspirations Page",
    key: "inspiraton-list",
    path: "/edit-inspiration",
    redirect: "/pages/inspirations/inspiration-list",
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="medium">category</Icon>,
    collapse: [
      {
        name: "Categories Page",
        key: "categories-list",
        route: "/pages/categories/categories-list",
        component: <CategoriesList />,
      },
      {
        name: "New Category",
        key: "new-category",
        route: "/pages/categories/new-category",
        component: <NewCategory />,
      },
    ],
  },
  {
    type: "route",
    component: <EditCategory />,
    route: "/pages/categories/edit-category/:id",
    key: "edit-category",
  },
  {
    name: "Categories Page",
    key: "categories-list",
    path: "/categories",
    redirect: "/pages/categories/categories-list",
  },
  {
    name: "Categories Page",
    key: "categories-list",
    path: "/edit-category",
    redirect: "/pages/categories/categories-list",
  },
  {
    type: "collapse",
    name: "Properties",
    key: "properties",
    icon: <Icon fontSize="medium">tune</Icon>,
    collapse: [
      {
        name: "Properties Page",
        key: "properties-list",
        route: "/pages/properties/properties-list",
        component: <PropertiesList />,
      },
      {
        name: "New Property",
        key: "new-property",
        route: "/pages/properties/new-property",
        component: <NewProperty />,
      },
    ],
  },
  {
    type: "route",
    component: <EditProperty />,
    route: "/pages/properties/edit-property/:id",
    key: "edit-property",
  },
  {
    name: "Properties Page",
    key: "properties-list",
    path: "/properties",
    redirect: "/pages/properties/properties-list",
  },
  {
    name: "Properties Page",
    key: "properties-list",
    path: "/edit-property",
    redirect: "/pages/properties/properties-list",
  },
  {
    type: "collapse",
    name: "Colors",
    key: "colors",
    icon: <Icon fontSize="medium">palette</Icon>,
    collapse: [
      {
        name: "Colors Page",
        key: "color-list",
        route: "/pages/colors/color-list",
        component: <ColorList />,
      },
      {
        name: "New Color",
        key: "new-product",
        route: "/pages/colors/new-color",
        component: <NewColor />,
      },
    ],
  },
  {
    name: "Colors Page",
    key: "color-list",
    path: "/colors",
    redirect: "/pages/colors/color-list",
  },
  {
    name: "Colors Page",
    key: "color-list",
    path: "/edit-color",
    redirect: "/pages/colors/color-list",
  },
  {
    type: "route",
    component: <EditColor />,
    route: "/pages/colors/edit-color/:id",
    key: "edit-color",
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        name: "Users Page",
        key: "users-list",
        route: "/pages/user/users-list",
        component: <UsersList />,
      },
      {
        name: "New User",
        key: "new-user",
        route: "/pages/user/new-user",
        component: <NewUser />,
      },
    ],
  },
  {
    type: "route",
    component: <EditUser />,
    route: "/pages/user/edit-user/:id",
    key: "edit-user",
  },
  {
    name: "Users Page",
    key: "users-list",
    path: "/users",
    redirect: "/pages/user/users-list",
  },
  {
    name: "Users Page",
    key: "users-list",
    path: "/edit-user",
    redirect: "/pages/user/users-list",
  },
  {
    type: "collapse",
    name: "Designers",
    key: "designers",
    icon: <Icon fontSize="medium">palette</Icon>,
    collapse: [
      {
        name: "Designers Page",
        key: "designers-list",
        route: "/pages/designers/designer-list",
        component: <DesignersList />,
      },
      {
        name: "New Designer",
        key: "new-designer",
        route: "/pages/designers/new-designer",
        component: <NewDesginer />,
      },
    ],
  },
  {
    name: "Designers Page",
    key: "designer-list",
    path: "/designers",
    redirect: "/pages/designers/designers-list",
  },
  {
    type: "route",
    component: <EditDesigner />,
    route: "/pages/designers/edit-designer/:id",
    key: "edit-designer",
  },
];

export default routes;
