import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { useNavigate, useParams } from "react-router-dom";

function EditUser() {
  const [enteredFirstName, setEnteredFirstName] = useState("");
  const [enteredLastName, setEnteredLastName] = useState("");
  const [enteredUsername, setEnteredUsername] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredType, setEnteredType] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    api
      .get(`/api/admin/user/${id}`)
      .then((response) => {
        const user = response.data.data;
        setEnteredFirstName(user?.firstname);
        setEnteredLastName(user?.lastname);
        setEnteredEmail(user?.email);
        setEnteredType(user?.type);
        setEnteredUsername(user?.username);
        setEnteredPassword(user?.password);
      })
      .catch(console.error);
  }, []);

  function editUserHandler() {
    const params = {
      firstname: enteredFirstName,
      lastname: enteredLastName,
      username: enteredUsername,
      type: enteredType,
      email: enteredEmail,
      password: enteredPassword,
    };
    api
      .post(`api/admin/user/${id}`, params)
      .then((response) => {
        console.log(response);
        toast.success("Successfully updated user!");
        navigate(-1);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={enteredFirstName || enteredEmail} />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit user
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "80%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  User Information
                </MDTypography>
                <MDBox mt={1} component="form" role="form" onSubmit={() => editUserHandler}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        type="text"
                        label="First Name"
                        value={enteredFirstName}
                        onChange={(e) => setEnteredFirstName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        type="text"
                        label="Last Name"
                        value={enteredLastName}
                        onChange={(e) => setEnteredLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        type="text"
                        label="Username"
                        value={enteredUsername}
                        onChange={(e) => setEnteredUsername(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        type="text"
                        label="Type"
                        value={enteredType}
                        onChange={(e) => setEnteredType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        type="email"
                        label="Email"
                        value={enteredEmail}
                        onChange={(e) => setEnteredEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormField
                        type="password"
                        label="Password"
                        value={enteredPassword}
                        onChange={(e) => setEnteredPassword(e.target.value)}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid item xs={12} lg={6} mb={3}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={() => editUserHandler()}>
                    save
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditUser;
