/* eslint-disable */
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

import BasicLayout from "layouts/authentication/components/BasicLayout";
import pfcLogo from "assets/images/parfum-flower-company-icon-white.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authenticate } from "services/api";
import { AuthContext } from "context/auth";
import { getUserData } from "services/api";
import api from "services/api";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [user, setUser] = useContext(AuthContext);

  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  async function signinHandler() {
    localStorage.setItem("override", true);

    setTimeout(() => {
      localStorage.removeItem("override");
    }, 5000);

    if (localStorage.getItem("token")) {
      try {
        await api.get("/api/user");
        navigate("/pages/products/product-list");
      } catch (error) {
        localStorage.removeItem("token");
        await performLogin();
      }
    } else {
      await performLogin();
    }
  }

  async function performLogin() {
    console.log("Performing login...");

    try {
      /* const authObj = await authenticate(enteredEmail, enteredPassword);
      const token = authObj.token; */

      const response = await api.post("/api/login", {
        email: enteredEmail,
        password: enteredPassword,
      });

      const token = response.data.data.token;

      localStorage.setItem("token", token);

      navigate("/pages/products/product-list");

      toast.success("Successfully logged in", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const userData = await getUserData();
      setUser(userData.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Authentication failed!", "Please check your credentials.");
    }
  }

  return (
    <BasicLayout style={{ backgroundColor: "#344767" }}>
      <MDBox p={3} textAlign="center">
        <img src={pfcLogo} alt="PFC LOGO" style={{ marginBottom: 30 }} />
      </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          // bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
          style={{ backgroundColor: "#C09923" }}
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={signinHandler}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={enteredEmail}
                onChange={(e) => setEnteredEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={enteredPassword}
                onChange={(e) => setEnteredPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                fullWidth
                style={{ backgroundColor: "#C09923" }}
                onClick={signinHandler}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <ToastContainer />
    </BasicLayout>
  );
}

export default Basic;
