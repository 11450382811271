/* eslint-disable */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { FormControl, InputLabel, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import "./spin.css";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export function Loading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      class="spin"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function NewProduct() {
  const [enteredName, setEnteredName] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [colors, setColors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [productId, setProductId] = useState("");
  const [selectedColors, setSelectedColors] = useState("");
  const [categoryValues, setCategoryValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [priority, setPriority] = useState(false);
  const navigate = useNavigate();

  console.log({ productId });

  const formData = new FormData();

  function handleImageChange(event) {
    const { files } = event.target;
    if (files.length > 0) {
      Array.from(files).forEach((file) => {
        formData.append(`image[]`, file);
      });
    }
  }

  useEffect(() => {
    api.get("/api/admin/products").then((response) => {
      console.log(response);
    });
    api.get("/api/admin/colors?items=5000").then((response) => {
      setColors(response.data.data.data);
    });
    api.get("/api/admin/properties?items=5000").then((response) => {
      setProperties(response.data.data.data);
    });
    api.get("/api/admin/categories").then((response) => {
      // fillInitialCategoriesValues(response.data.data.product, product);
      setCategoryValues(response.data.data.product);
    });
  }, []);
  const toggleCategoryValue = (category) => {
    const categories = [...categoryValues];
    const categoryValue = categories.find((cat) => cat.id === category);
    categories[categories.indexOf(categoryValue)].selected = !categoryValue.selected;
    setCategoryValues(categories);
  };

  function newProductHandler() {
    const imageInput = document.getElementById("image").files.length;

    if (enteredName && enteredDescription && selectedColors.length > 0 && imageInput >= 1) {
      setLoading(true);

      api
        .post("api/admin/product/create", {
          name: enteredName,
          description: enteredDescription,
          color_id: selectedColors[0],
          properties: properties.filter(
            (prop) => prop.value !== "" && prop.value !== null && prop.value !== undefined
          ),
          categories: categoryValues.filter((cat) => cat.selected),
          priority: priority,
        })
        .then((response) => {
          setProductId(response.data.data.id);
          toast.success("Successfully created product!");
          console.log({ response });
          // window.location.reload(false);
          api
            .post(`api/admin/product/${response.data.data.id}/image/add`, formData, {
              headers: {
                "Content-Type": "multipart-form-data",
              },
            })
            .then((res) => {
              console.log(res);
              navigate("/pages/products/product-list");
            })
            .catch(console.error)
            .finally(() => setLoading(false));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    } else if (!enteredName) {
      toast.error("Name is required field!");
    } else if (!enteredDescription) {
      toast.error("Description is required field!");
    } else if (!selectedColors) {
      toast.error("Please select a color!");
    } else {
      toast.error("Please add an image!");
    }
  }

  const handleColorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColors(typeof value === "string" ? value.split(",") : value);
  };

  const fillPropertyValue = (propertyId, value) => {
    const allProperties = [...properties];
    const property = allProperties.find((prop) => prop.id === propertyId);
    allProperties[allProperties.indexOf(property)].value = value;
    setProperties(allProperties);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Add new product
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Product Information
                </MDTypography>
                <MDBox
                  mt={1}
                  component="form"
                  role="form"
                  encType="multipart/form-data"
                  onSubmit={() => newProductHandler}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        multiline
                        rows={5}
                        label="Description"
                        value={enteredDescription}
                        onChange={(e) => setEnteredDescription(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <p>Categories</p>
                      {categoryValues.map((category) => (
                        <FormGroup key={category.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={category.selected}
                                onChange={() => toggleCategoryValue(category.id)}
                              />
                            }
                            label={category.name}
                          />
                        </FormGroup>
                      ))}
                    </Grid>
                    <Grid item xs={12} sm={12} spacing={3}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-multiple-name-label">Color</InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={selectedColors}
                          onChange={handleColorChange}
                          input={<OutlinedInput label="Color" />}
                          MenuProps={MenuProps}
                          sx={{ height: 40 }}
                        >
                          {colors.map((color) => (
                            <MenuItem key={color.id} value={color.id}>
                              {color.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      {properties.length ? (
                        properties?.map((property) => (
                          <Grid container item xs={12} key={property.id}>
                            <Grid item xs={4}>
                              <label
                                htmlFor={`propertyInput-${property.id}`}
                                style={{ fontSize: "1rem" }}
                              >
                                {property.name}
                              </label>
                            </Grid>
                            <Grid item xs={8}>
                              <FormControl fullWidth>
                                <InputLabel id={`property-${property.id}-dropdown`}>
                                  {property.name}
                                </InputLabel>
                                <Select
                                  value={property.value ? property.value : "-"}
                                  labelId={`property-${property.id}-dropdown`}
                                  label="Property"
                                  MenuProps={MenuProps}
                                  sx={{ height: 40 }}
                                  onChange={(e) => fillPropertyValue(property.id, e.target.value)}
                                >
                                  <MenuItem value="-">-</MenuItem>
                                  {property.values.length &&
                                    property.values.map((value) => (
                                      <MenuItem key={value.id} value={value.value}>
                                        {value.value}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12} s={12}>
                      <Typography variant="caption" display="block" gutterBottom pt={1} m={2}>
                        <Checkbox
                          checked={priority}
                          onChange={(event) => setPriority(event.target.checked)}
                        />
                        Priority
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <input
                        id="image"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        multiple
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid item xs={12} lg={6} mb={3}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={newProductHandler}>
                    {loading ? <Loading /> : "Save"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewProduct;
