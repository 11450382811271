import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import api from "services/api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(id, name) {
  return { id, name };
}

function CategoriesList() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    api.get("/api/admin/categories").then((response) => {
      setData(response.data.data.inspiration);
      setData2(response.data.data.product);
    });
  }, []);
  const rows = [];

  useEffect(() => {
    data.forEach((datas) => {
      rows.push(createData(datas.id, datas.name));
    });
    data2.forEach((datas2) => {
      rows.push(createData(datas2.id, datas2.name));
    });
  }, [data]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  function newCategoryButton() {
    navigate("/pages/categories/new-category");
  }

  function deleteCategory(id) {
    api
      .delete(`api/admin/category/${id}`)
      .then((response) => {
        console.log(response);
        toast.success("Successfully deleted category!");
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Some error occured!");
      });
  }
  function editCategory(id) {
    navigate(`/pages/categories/edit-category/${id}`);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={() => newCategoryButton()}>
            new category
          </MDButton>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mt={1} mb={2}>
              <MDTypography variant="body2" color="text">
                Inspirations
              </MDTypography>
            </MDBox>
            <TableContainer component={Paper} sx={{ width: "70%" }}>
              {/* E oqem head ce sboeshte me heead */}
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        <Icon
                          fontSize="small"
                          sx={{ color: "blue", cursor: "pointer" }}
                          onClick={() => editCategory(row.id)}
                        >
                          edit
                        </Icon>
                        <Icon
                          fontSize="small"
                          sx={{ color: "red", cursor: "pointer" }}
                          onClick={() => deleteCategory(row.id)}
                        >
                          delete
                        </Icon>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mt={1} mb={2}>
              <MDTypography variant="body2" color="text">
                Products
              </MDTypography>
            </MDBox>
            <TableContainer component={Paper} sx={{ width: "70%" }}>
              {/* E oqem head ce sboeshte me heead */}
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                  {data2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        <Icon
                          fontSize="small"
                          sx={{ color: "blue", cursor: "pointer" }}
                          onClick={() => editCategory(row.id)}
                        >
                          edit
                        </Icon>
                        <Icon
                          fontSize="small"
                          sx={{ color: "red", cursor: "pointer" }}
                          onClick={() => deleteCategory(row.id)}
                        >
                          delete
                        </Icon>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CategoriesList;
