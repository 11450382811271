import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import "../../products/new-product/spin.css";

export function Loading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="spin"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  );
}
function NewDesginer() {
  const [enteredName, setEnteredName] = useState("");
  const [loading, setLoading] = useState(false);

  function newDesignerHandler() {
    const params = { name: enteredName };
    if (params) {
      setLoading(true);
      api
        .post("api/admin/designer/create", params)
        .then((response) => {
          console.log(response);
          toast.success("Successfully created designer!");
          setEnteredName("");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Wrong parameters");
        })
        .finally(() => setLoading(false));
    } else if (!enteredName) {
      toast.error("Name is required filed!");
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Add new designer
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Designer Information
                </MDTypography>
                <MDBox mt={1} component="form" role="form" onSubmit={() => newDesignerHandler}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid item xs={12} lg={6} mb={3}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={() => newDesignerHandler()}>
                    {loading ? <Loading /> : "Save"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewDesginer;
