/* eslint-disable */
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";

import { useNavigate, useParams } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditProduct() {
  const [enteredName, setEnteredName] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [colors, setColors] = useState([]);
  const [selectedColors, setSelectedColors] = useState("");
  const [propertyValues, setPropertyValues] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [priority, setPriority] = useState(null);
  const [product, setProduct] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  function getData() {
    api.get(`/api/admin/product/${id}`).then((response) => {
      console.log(response);
      const product = response.data.data;
      setProduct(product);
      setEnteredName(product?.name);
      setEnteredDescription(product?.description);
      setSelectedColors(product?.color_id);
      setPriority(product?.priority);
      api.get("/api/admin/properties").then((response) => {
        fillInitialPropertyValues(response.data.data.data, product);
      });
      api.get(`/api/admin/categories`).then((response) => {
        fillInitialCategoriesValues(response.data.data.product, product);
      });
    });
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    api.get("/api/admin/colors").then((response) => {
      setColors(response.data.data.data);
    });
  }, []);

  const formData = new FormData();

  function handleImageChange(event) {
    const { files } = event.target;
    if (files.length > 0) {
      Array.from(files).forEach((file) => {
        formData.append(`image[]`, file);
      });
    }
  }

  function newProductHandler() {
    let properties = propertyValues.filter(
      (prop) =>
        (prop.multiple && prop.multipleValues?.length > 0) ||
        (prop.value !== "" && prop.value !== null && prop.value !== undefined)
    );
    properties.forEach((prop) => {
      if (prop.multiple && prop.multipleValues.length > 0 && prop.multipleValues[0] !== "-") {
        prop.value = prop.multipleValues.join(", ");
      }
    });

    api
      .post(`api/admin/product/${id}`, {
        name: enteredName,
        description: enteredDescription,
        color_id: selectedColors,
        properties: properties,
        categories: categoryValues.filter((cat) => cat.selected),
        priority: priority,
      })
      .then((response) => {
        toast.success("Successfully updated product!");
        navigate(-1);

        if (!formData.entries().next().done) {
          api
            .post(`api/admin/product/${response.data.data.id}/image/add`, formData, {
              headers: {
                "Content-Type": "multipart-form-data",
              },
            })
            .then((res) => {})
            .catch(console.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function deleteImage(imageId) {
    api
      .post(`api/admin/product/${id}/image/remove`, {
        image: [imageId],
      })
      .then((res) => {
        getData();
      })
      .catch(console.error);
  }

  const handleColorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColors(value);
  };

  const fillInitialCategoriesValues = (data, product) => {
    let categories = [...data];
    let initialCategories = [];
    categories.forEach((cat) => {
      let category = {
        id: cat.id,
        name: cat.name,
        selected: false,
      };
      let isSet =
        product.categories.find((pc) => {
          return pc.id === cat.id;
        }) !== undefined;
      if (isSet) {
        category.selected = true;
      }
      initialCategories.push(category);
    });
    setCategoryValues(initialCategories);
  };

  const toggleCategoryValue = (category) => {
    let categories = [...categoryValues];
    let categoryValue = categories.find((cat) => cat.id === category);
    categories[categories.indexOf(categoryValue)].selected = !categoryValue.selected;
    setCategoryValues(categories);
  };

  const fillInitialPropertyValues = (data, product) => {
    let properties = [...data];
    let initialProperties = [];
    properties.forEach((prop) => {
      let existingProperty = product.properties.find((productProp) => productProp.id === prop.id);
      let property = {
        name: prop.name,
        id: prop.id,
        possibleValues: prop.values,
        multiple: prop.multiple,
        value: "-",
        multipleValues: ["-"],
      };

      if (existingProperty) {
        if (prop.multiple) {
          property.multipleValues = existingProperty.value.split(", ");
        } else {
          property.value = existingProperty.value;
        }
      }
      initialProperties.push(property);
    });
    setPropertyValues(initialProperties);
  };

  const fillPropertyValue = (propertyId, value) => {
    let allProperties = [...propertyValues];
    let property = allProperties.find((prop) => prop.id === propertyId);
    if (property.multiple) {
      allProperties[allProperties.indexOf(property)].multipleValues = value;
    } else {
      allProperties[allProperties.indexOf(property)].value = value;
    }
    setPropertyValues(allProperties);
  };

  const imageStyles = {
    width: "150px",
    height: "150px",
    borderRadius: "100%",
    objectFit: "cover",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={product?.name} />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit product
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} display="flex" justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card sx={{ width: "70%" }}>
              <MDBox p={3}>
                <MDTypography variant="h5" display="flex" justifyContent="center">
                  Product Information
                </MDTypography>
                <MDBox
                  mt={1}
                  component="form"
                  role="form"
                  encType="multipart/form-data"
                  onSubmit={() => newProductHandler}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        type="text"
                        label="Name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        multiline
                        rows={5}
                        label="Description"
                        value={enteredDescription}
                        onChange={(e) => setEnteredDescription(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <p>Categories</p>
                      {categoryValues.map((category) => (
                        <FormGroup key={category.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={category.selected}
                                onChange={() => toggleCategoryValue(category.id)}
                              />
                            }
                            label={category.name}
                          />
                        </FormGroup>
                      ))}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-multiple-name-label">Color</InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={selectedColors}
                          onChange={handleColorChange}
                          input={<OutlinedInput label="Color" />}
                          MenuProps={MenuProps}
                          sx={{ height: 40 }}
                        >
                          {colors.map((color) => (
                            <MenuItem key={color.id} value={color.id}>
                              {color.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                      {propertyValues.map((property) => (
                        <Grid container item xs={12} key={property.id}>
                          <Grid item xs={4}>
                            <label
                              htmlFor={`propertyInput-${property.id}`}
                              style={{ fontSize: "1rem" }}
                            >
                              {property.name}
                            </label>
                          </Grid>
                          <Grid item xs={8}>
                            <FormControl fullWidth>
                              <InputLabel id={`property-${id}-dropdown`}>
                                {property.name}
                              </InputLabel>
                              <Select
                                value={property.multiple ? property.multipleValues : property.value}
                                labelId={`property-${id}-dropdown`}
                                label="Property"
                                multiple={property.multiple}
                                MenuProps={MenuProps}
                                sx={{ height: 40 }}
                                onChange={(e) => fillPropertyValue(property.id, e.target.value)}
                              >
                                <MenuItem value="-">-</MenuItem>
                                {property.possibleValues.map((possibleValue) => (
                                  <MenuItem key={possibleValue.id} value={possibleValue.value}>
                                    {possibleValue.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item xs={12} s={12}>
                      <Typography variant="caption" display="block" gutterBottom pt={1} m={2}>
                        <Checkbox
                          checked={priority}
                          onChange={(event) => setPriority(event.target.checked)}
                        />
                        Priority
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <input type="file" onChange={handleImageChange} multiple />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid container item columns={12}>
                {product?.images.map((image) => (
                  <Grid item xs={4} key={image.id} style={{ textAlign: "center" }}>
                    <img src={image.url} alt={image.name} style={imageStyles} />
                    <Icon
                      fontSize="small"
                      onClick={() => deleteImage(image.id)}
                      sx={{ color: "red", cursor: "pointer" }}
                    >
                      delete
                    </Icon>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} lg={6} mb={3}>
                <MDBox display="flex" justifyContent="flex-end">
                  <MDButton variant="gradient" color="info" onClick={() => newProductHandler()}>
                    save
                  </MDButton>
                </MDBox>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditProduct;
